// import React, {memo} from 'react';
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import './CustomCarousel.css';

// // Memoized component for each image
// const CarouselImage = memo(({src, alt, className}) => (
//   <img src={src} alt={alt} loading="lazy" className={className}/>
// ));

// const CustomCarousel = memo(() => {
//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 1000, // Smooth transition speed
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 5000, // Delay between slides
//     arrows: false,
//     fade: true, // Enable fade effect for smoother transitions
//     adaptiveHeight: false,
//     pauseOnHover: false,
//     lazyLoad: 'ondemand', // Lazy load images to improve performance
//   };

//   return (
//     <div className="carousel-background">
//       <Slider {...settings}>

//         <div className="carousel-slide">
//           <CarouselImage
//             src="/assets/img/carousel/5.jpg"
//             alt="Background 5"
//             className="fifth-image" // Position in the center
//           />
//           <div className="carousel-text">
//             <h2>Vision-Driven Safety in Industry</h2>
//             <p>Real-time monitoring of an industrial environment maintains safety standards, protecting workers and
//               preventing violations</p>
//           </div>
//         </div>

//         <div className="carousel-slide">
//           <CarouselImage
//             src="/assets/img/carousel/2.jpg"
//             alt="Background 2"
//             className="second-image" // Another class for different position
//           />
//           <div className="carousel-text">
//             <h2>Visual Inspection for Quality Control</h2>
//             <p>Automatically detects defects and misalignments with CAD model, increasing productivity</p>
//           </div>
//         </div>

//         <div className="carousel-slide">
//           <CarouselImage
//             src="/assets/img/carousel/olives.jpg"
//             alt="Background 3"
//             className="third-image" // Position in the center
//           />
//           <div className="carousel-text">
//             <h2>Smart Farming Solutions with Vision Systems</h2>
//             <p>Optimizes costs through real-time monitoring of the production process</p>
//           </div>
//         </div>

//         <div className="carousel-slide">
//           <CarouselImage
//             src="/assets/img/carousel/3.jpg"
//             alt="Background 1"
//             className="first-image"
//           />
//           <div className="carousel-text">
//             <h2>Vision-based solutions to tackle traffic challenges</h2>
//             {/*<p></p>*/}
//           </div>
//         </div>

//         {/*Replace image*/}

//         {/*<div className="carousel-slide">*/}
//         {/*  <CarouselImage*/}
//         {/*    src="/assets/img/carousel/4.1.jpg"*/}
//         {/*    alt="Background 4"*/}
//         {/*    className="fourth-image" // Position in the center*/}
//         {/*  />*/}
//         {/*  <div className="carousel-text">*/}
//         {/*    <h2>Automated Solutions for Flawless Inspection</h2>*/}
//         {/*    <p>Ensures labels and barcodes meet standards, minimizing packaging errors.</p>*/}
//         {/*  </div>*/}
//         {/*</div>*/}


//       </Slider>
//     </div>
//   );
// });

// export default CustomCarousel;
import React, {useContext} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './CustomCarousel.css';
import { MainContext } from "../../context/MainContext";

// Component for each image
const CarouselImage = ({ src, alt, className }) => (
  <img src={src} alt={alt} loading="lazy" className={className} />
);

// Translation object for multiple languages
const slideContent = [
  {
    imgSrc: "/assets/img/carousel/5.jpg",
    alt: "Background 5",
    text: {
      "en": {
        title: "Vision-Driven Safety in Industry",
        desc: "Real-time monitoring of an industrial environment maintains safety standards, protecting workers and preventing violations"
      },
      "no": {
        title: "Visjonsdrevet sikkerhet i industrien",
        desc: "Sanntidsovervåking av industrielt miljø opprettholder sikkerhetsstandarder, beskytter arbeidere og forhindrer brudd"
      },
      "mn": {
        title: "Sigurnost u industriji vođena vizijom",
        desc: "Praćenje industrijskog okruženja u stvarnom vremenu održava sigurnosne standarde, štiti radnike i sprječava prekršaje"
      }
    },
    className: "fifth-image"
  },
  {
    imgSrc: "/assets/img/carousel/2.jpg",
    alt: "Background 2",
    text: {
      "en": {
        title: "Visual Inspection for Quality Control",
        desc: "Automatically detects defects and misalignments with CAD model, increasing productivity"
      },
      "no": {
        title: "Visuell inspeksjon for kvalitetskontroll",
        desc: "Oppdager automatisk feil og feiljusteringer med CAD-modellen, noe som øker produktiviteten"
      },
      "mn": {
        title: "Vizuelna inspekcija za kontrolu kvaliteta",
        desc: "Automatski otkriva nedostatke u odnosu na CAD model, povećavajući produktivnost"
      }
    },
    className: "second-image"
  },
  {
    imgSrc: "/assets/img/carousel/olives.jpg",
    alt: "Background 3",
    text: {
      "en": {
        title: "Smart Farming Solutions with Vision Systems",
        desc: "Optimizes costs through real-time monitoring of the production process"
      },
      "no": {
        title: "Smarte landbruksløsninger med visionsystemer",
        desc: "Optimaliserer kostnader gjennom sanntidsovervåking av produksjonsprosessen"
      },
      "mn": {
        title: "Pametna rješenja u poljoprivredi pomoću vizuelnih sistema",
        desc: "Optimizuje troškove kroz praćenje procesa proizvodnje u stvarnom vremenu"
      }
    },
    className: "third-image"
  },
  {
    imgSrc: "/assets/img/carousel/3.jpg",
    alt: "Background 1",
    text: {
      "en": {
        title: "Vision-based solutions to tackle traffic challenges",
        desc: ""
      },
      "no": {
        title: "Visjonsbaserte løsninger for å takle trafikkutfordringer",
        desc: ""
      },
      "mn": {
        title: "Rješenja zasnovana na viziji za prevazilaženje saobraćajnih izazova",
        desc: ""
      }
    },
    className: "first-image"
  }
];

const CustomCarousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000, // Smooth transition speed
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000, // Delay between slides
    arrows: false,
    fade: true, // Enable fade effect for smoother transitions
    adaptiveHeight: false,
    pauseOnHover: false,
    lazyLoad: 'ondemand', // Lazy load images to improve performance
  };

  const { language,  } = useContext(MainContext);

  return (
    <div className="carousel-background">
      <Slider {...settings}>
        {slideContent.map((slide, index) => (
          <div className="carousel-slide" key={index}>
            <CarouselImage
              src={slide.imgSrc}
              alt={slide.alt}
              className={slide.className}
            />
            <div className="carousel-text">
              <h2>{slide.text[language]?.title}</h2>
              <p>{slide.text[language]?.desc}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CustomCarousel;
