import React from "react";
import AboutUs from "../views/inner-pages/about/AboutUs";

import NotFound from "../views/NotFound";
import { Routes, Route } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";
import Contact from "../views/inner-pages/Contact";
import FaqFaintech from "../views/inner-pages/FaqFaintech";
import Terms from "../views/inner-pages/Terms";
import Privacy from "../views/inner-pages/Privacy";
import BlogGrid from "../views/inner-pages/blog/BlogGrid";
import ImageClassification from "../views/inner-pages/service/ImageClassification";
import Service from "../views/inner-pages/service/Service";
import ImageSegmentation from "../views/inner-pages/service/ImageSegmentation";
import ObjectDetection from "../views/inner-pages/service/ObjectDetection";
import ObjectCounting from "../views/inner-pages/service/ObjectCounting";
import Tracking from "../views/inner-pages/service/Tracking";
import DeepLearning3d from "../views/inner-pages/service/DeepLearning3d";
import CompressionOptimization from "../views/inner-pages/service/CompressionOptimization";
import EmbeddedDevices from "../views/inner-pages/service/EmbeddedDevices";
import IndustrialInspection from "../views/inner-pages/service/IndustrialInspection";
import AutgmentedReality from "../views/inner-pages/service/AugmentedReality";
import CameraCalibration from "../views/inner-pages/service/CameraCalibration";
import OurTeam from "../views/inner-pages/our-team/OurTeam";
import RaspberryPiNoirCameraBlog from "../components/blog/all_blogs/RaspberryPiNoirCameraBlog";
import ComputerWellBeingBlog from "../components/blog/all_blogs/ComputerWellBeing"
import BlogMasonry from "../views/inner-pages/blog/BlogMasonry";
import BlogSidebar from "../views/inner-pages/blog/BlogSidebar";
import BlogDetails from "../views/inner-pages/blog/BlogDetails";
import BlogDetailsSidebar from "../views/inner-pages/blog/BlogDetailsSidebar";

const AllRoutes = () => {
  return (
    <>
      <ScrollTopBehaviour />
      <Routes>
        <Route path="/" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<BlogSidebar />} />
        <Route path="/faq" element={<FaqFaintech/>} />
        <Route path="/privacy" element={<Privacy/>} />
        <Route path="/terms" element={<Terms/>} />
        <Route path="/image-classification" element={<ImageClassification/>} />
        <Route path="/image-segmentation" element={<ImageSegmentation/>} />
        <Route path="/object-detection" element={<ObjectDetection/>} />
        <Route path="/object-counting" element={<ObjectCounting/>} />
        <Route path="/tracking" element={<Tracking/>} />
        <Route path="/deep-learning-3d" element={<DeepLearning3d/>} />
        <Route path="/compression-optimization" element={<CompressionOptimization/>} />
        <Route path="/embedded-devices" element={<EmbeddedDevices/>} />
        <Route path="/industrial-inspection" element={<IndustrialInspection/>} />
        <Route path="/augmented-reality" element={<AutgmentedReality/>} />
        <Route path="/camera-calibration" element={<CameraCalibration/>} />
        <Route path="/services" element={<Service/>} />
        <Route path="/our-team" element={<OurTeam/>} />
        <Route path="/blog-details-anpr" element={<RaspberryPiNoirCameraBlog/>} />
        <Route path="/blog-details-posture" element={<ComputerWellBeingBlog/>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
