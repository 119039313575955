import React, { useContext } from "react";
import { MainContext } from "../../../context/MainContext";

const CopyRight = () => {
  const { language } = useContext(MainContext);

  const translations = {
    en: "All Rights Reserved",
    no: "Alle rettigheter reservert",
    mn: "Sva prava zadržana"
  };

  return (
    <div className="row align-items-center justify-content-center">
      <div className="col-12 col-md">
      </div>
      <div className="col-12 col-md text-md-center text-lg-center">
        <p className="ptf-footer-copyright has-black-color">
          ©{new Date().getFullYear()}{" "}
          <span>Faintech</span>. {translations[language] || translations.en}.
        </p>
      </div>
      <div className="col-12 col-lg text-md-center text-lg-end">
        <div className="ptf-footer-socials has-black-color ">
        </div>
      </div>
    </div>
  );
};

export default CopyRight;
