import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../components/header/HeaderDefault";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";
import Faq from "../../../components/faq/Faq";
import Approach2 from "../../../components/service/Approach2";
import ImageClassificationContent from "../../../content/services/ImageClassification.json"
import { MainContext } from "../../../context/MainContext";
import ServicesLabels from "../../../content/services/ServicesLabels.json";
import ServicesAllContent from "../../../content/services/ServicesAll.json";
import parse from 'html-react-parser';

const ImageClassification = () => {
  const currentPage = window.location.pathname.split("/").pop();
  const serviceContent = ServicesAllContent.find((service, i) => (service.link === currentPage))
  const { language, } = useContext(MainContext);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Faintech | Image Classification</title>
        <meta name="description" content="Explore various applications of image classification, including autonomous vehicles, healthcare, e-commerce, security, agriculture, and wildlife conservation. Learn how image classification technology impacts different industries." />
        <meta name="keywords" content="image classification, autonomous vehicles, healthcare, e-commerce, security, surveillance, agriculture, wildlife conservation" />
        <meta name="author" content="Faintech" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--service-detail">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-9">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading">{serviceContent.titlePart1[language]}</h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="fz-18">
                      {/* {serviceContent.descriptions[language]} */}

                      {parse(ImageClassificationContent.description_long[language])}











                      

















                    </p>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
                  ></div>
                </div>
                <div className="col-lg-12">
                  {/* <!--Animated Block--> */}
                  {/* <ImageGridFour />  */}
                </div>
              </div>
            </div>
          </section>
          {/* Top Portion */}

          <section>
            {/* <!--Spacer--> */}
            {/*<div*/}
            {/*  className="ptf-spacer"*/}
            {/*  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}*/}
            {/*></div>*/}
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-8">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    {/*<p className="fz-18">*/}
                    {/*  {ImageClassificationContent.description_long[language]}*/}
                    {/*</p>*/}
                  </div>
                  {/* <!--Spacer--> */}
                  {/*<div*/}
                  {/*  className="ptf-spacer"*/}
                  {/*  style={{ "--ptf-xxl": "4.375rem" }}*/}
                  {/*></div>*/}
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    {/*<h3>{ServicesLabels.applications[language]}</h3>*/}
                    {/* <!--Spacer--> */}
                    {/*<div*/}
                    {/*  className="ptf-spacer"*/}
                    {/*  style={{ "--ptf-xxl": "1.875rem" }}*/}
                    {/*></div>*/}
                    {/*<p className="fz-18">*/}
                    {/*  {ImageClassificationContent.applications.map((item, i) => (*/}
                    {/*      <p dangerouslySetInnerHTML={{__html: item[language]}}/>*/}
                    {/*    ))}*/}
                    {/*</p>*/}
                  </div>
                  {/* <!--Spacer--> */}

                  {/* <!--Animated Block--> */}
                  {/* <!--Spacer--> */}
                  {/*<div*/}
                  {/*  className="ptf-spacer"*/}
                  {/*  style={{ "--ptf-xxl": "6.875rem" }}*/}
                  {/*></div>*/}
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos-delay="0"
                  >
                    <h3>{ServicesLabels.our_approach[language]}</h3>
                    {/* <!--Spacer--> */}
                  </div>
                  {/* <!--Spacer--> */}

                  {/* <!--Process Steps--> */}
                  <Approach2 />
                </div>
                <div className="col-xl-4">
                  <div className="ptf-sidebar ptf-sidebar--right">
                    {/* <!--Widget--> */}
                    {/* <!--Widget--> */}
                    <div className="ptf-widget ptf-widget-faq">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h4 className="ptf-widget-title">FAQs</h4>
                        <Faq />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
          {/* End .main content with sidebar */}
        </div>
        {/* End .ptf-page */}
      </div>
      {/* End ptf-main */}

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ImageClassification;
