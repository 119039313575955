import React, { useContext } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { MainContext } from "../../context/MainContext";


const BlogGridSLider = ({blogContent}) => {
  const { language, } = useContext(MainContext);
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      <div className="swiper-slide grid-slider">
        <Slider {...settings} className="arrow-none">
          {blogContent.map((val, i) => (
            <div className="ptf-news-slide" key={i}>
              <div className="ptf-news-slide__media">
                <img
                  src={`assets/img/blog/slider/${val.img}`}
                  alt=""
                  loading="lazy"
                />
              </div>
              <div className="ptf-news-slide__content">
                <div className="ptf-news-slide__meta">
                  <span className="cat">{val.categories[language]}</span>
                  <span className="date">{val.date[language]}</span>
                </div>
                <h3 className="ptf-news-slide__title" style={{color: "rgb(20 20 20)"}}>
                  <Link to={`${val.link}`}>{val.title[language]}</Link>
                </h3>
                <div className="ptf-news-slide__excerpt">
                  <p style={{color: "rgb(255 255 255)"}}><b>{val.description[language]}</b></p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        
      </div>
    </>
  );
};

export default BlogGridSLider;
