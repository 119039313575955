// import React, {useContext} from "react";
import React from "react";
// import { Link } from "react-router-dom";
// import {MainContext} from "../../context/MainContext";
// import FooterContent from "../../content/footer/FooterContent.json"

// const linkList = [
//   {
//     itemName: {
//       'en': "Faqs",
//       'no': "Faq"
//     },
//     link: "/faq",
//   },
//   {
//     itemName: {
//       'en': "Term & Conditions",
//       'no': 'Vilkår og betingelser'
//     },
//     link: "/terms",
//   },
//   {
//     itemName: {
//       'en': "Privacy Policy",
//       'no': 'Personvernerklæring'
//     },
//     link: "/privacy",
//   }
// ];

const Footer = () => {
  // const { language,  } = useContext(MainContext);

  return (
    <div className="row">
      {/*<div className="col-12 col-xl-6">*/}
      {/*  /!* <!--Animated Block--> *!/*/}
      {/*  <div className="ptf-animated-block" data-aos="fade" data-aos-delay="0">*/}
      {/*    <div*/}
      {/*      className="ptf-footer-heading has-black-color"*/}
      {/*      style={{ maxWidth: "37.5rem" }}*/}
      {/*    >*/}
      {/*      {FooterContent.footer_boost_your_business[language]}*/}
      {/*    </div>*/}

      {/*    <div className="ptf-footer-heading has-black-color">*/}
      {/*      {FooterContent.footer_begin_by[language]}*/}
      {/*      <Link*/}
      {/*        className="has-accent-1 text-decoration-underline"*/}
      {/*        to="/contact"*/}
      {/*      >*/}
      {/*        {FooterContent.footer_saying_hei[language]}*/}
      {/*      </Link>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className="col-12 col-md-6 col-xl-3">*/}
      {/*  /!* <!--Animated Block--> *!/*/}
      {/*  <div*/}
      {/*    className="ptf-animated-block"*/}
      {/*    data-aos="fade"*/}
      {/*    data-aos-delay="100"*/}
      {/*  >*/}
      {/*    <div className="ptf-widget ptf-widget-links has-black-color">*/}
      {/*      <h4 className="ptf-widget-title">{FooterContent.footer_links[language]}</h4>*/}
      {/*      <ul>*/}
      {/*        {linkList.map((val, i) => (*/}
      {/*          <li key={i}>*/}
      {/*            <a href={val.link}>{val.itemName[language]}</a>*/}
      {/*          </li>*/}
      {/*        ))}*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};

export default Footer;
