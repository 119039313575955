import React, {useContext} from "react";
import {MainContext} from "../../context/MainContext";

const approachContent = [
  {
    subTitle: "1",
    title: {
      "en": "Book Free Consultation",
      "no": "Bestill gratis konsultasjon",
      "mn": "Zakažite besplatnu konsultaciju"
    },
    descriptions: {
      "en": "This serves as an opportunity to discuss your goals, understand your services, and assess if there is a good mutual fit.",
      "no": "Dette fungerer som en mulighet til å diskutere målene dine, forstå tjenestene dine og vurdere om det er en god gjensidig tilpasning.",
      "mn": "Ovo je prilika da razgovaramo o Vašim ciljevima, razumijemo Vaše usluge i procijenimo da li postoji dobra međusobna kompatibilnost."
    },
    delayAnimation: "0",
  },
  {
    subTitle: "2",
    title: {
      "en": "Define the scope of work",
      "no": "Definer omfanget av arbeidet",
      "mn": "Definišite opseg posla"
    },
    descriptions: {
      "en": "We clearly outline the specific deliverables, timelines, and any constraints or preferences you have. This helps set expectations and ensures everyone is on the same page.",
      "no": "Vi skisserer tydelig de spesifikke leveransene, tidslinjene og eventuelle begrensninger eller preferanser du har. Dette bidrar til å sette forventninger og sikrer at alle er på samme side.",
      "mn": "Jasno ćemo naznačiti specifične isporuke, vremenske rokove i sve Vaše zahtjeve ili preferencije. Ovo pomaže u postavljanju očekivanja i osigurava da svi budu na istoj strani."
    },
    delayAnimation: "100",
  },
  {
    subTitle: "3",
    title: {
      "en": "Establish communication channels",
      "no": "Etablere kommunikasjonskanaler",
      "mn": "Uspostavite kanale komunikacije"
    },
    descriptions: {
      "en": "Determine the preferred communication channels and frequency of updates. This could include regular meetings, progress reports, or a dedicated project management tool.",
      "no": "Bestem de foretrukne kommunikasjonskanalene og frekvensen av oppdateringer. Dette kan inkludere regelmessige møter, fremdriftsrapporter eller et dedikert prosjektstyringsverktøy.",
      "mn": "Odredite preferirane kanale komunikacije i učestalost ažuriranja. To može uključivati redovne sastanke, izvještaje o napretku ili namjenski alat za upravljanje projektima."
    },
    delayAnimation: "200",
  },
  {
    subTitle: "4",
    title: {
      "en": "Collaborate and provide feedback",
      "no": "Samarbeid og gi tilbakemelding",
      "mn": "Saradnja i povratne informacije"
    },
    descriptions: {
      "en": "Collaboration and constructive communication are key to achieving the desired outcomes.",
      "no": "Samarbeid og konstruktiv kommunikasjon er nøkkelen til å oppnå de ønskede resultatene.",
      "mn": "Saradnja i konstruktivna komunikacija su ključni za postizanje željenih rezultata."
    },
    delayAnimation: "300",
  },
];


const Approach = () => {
  const { language,  } = useContext(MainContext);
  return (
    <div
      className="row"
      style={{
        "--bs-gutter-x": "3.75rem",
        "--bs-gutter-y": "7.5rem",
      }}
    >
      {approachContent.map((val, i) => (
        <div className="col-xl-4 col-lg-6" key={i}>
          {/* <!--Animated Block--> */}
          <div
            className="ptf-animated-block"
            data-aos="fade"
            data-aos-delay={val.delayAnimation}
          >
            {/* <!--Approach Box--> */}
            <div
              className="ptf-approach-box"
              style={{
                "--ptf-title-color": "var(--ptf-color-white)",
              }}
            >
              <div className="ptf-approach-box__subtitle">{val.subTitle}</div>
              <h3 className="ptf-approach-box__title h1">{val.title[language]}</h3>
              <div className="ptf-approach-box__content">
                <p>{val.descriptions[language]}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Approach;
