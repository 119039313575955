import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import BlogSidebarPost from "../../../components/blog/blog-sidebar/BlogSidebarPost";
import BlogFive from "../../../components/blog/BlogFive";
import BlogFour from "../../../components/blog/BlogFour";
import Pagination from "../../../components/blog/Pagination";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";
import HeaderDefault from "../../../components/header/HeaderDefault";
import BlogGridSLider from "../../../components/blog/BlogGridSLider";
import { MainContext } from "../../../context/MainContext";
import "./BlogGridSLider.scss"

const translations = {
  pageTitle: {
    en: "Blogs",
    no: "Blogger",
    mn: "Blogovi"
  }
};

const blogContent = [
  {
    img: "anpr2.png",
    link: "/blog-details-anpr",
    date: {
      en: "May 08, 2024",
      no: "8. mai, 2024",
      mn: "8. maj, 2024"
    },
    title: {
      "en": "Exploring ANPR with the Raspberry Pi NoIR Camera",
      "no": "Utforsking av ANPR med Raspberry Pi NoIR-kameraet",
      "mn": "Istraživanje ANPR-a sa Raspberry Pi NoIR kamerom"
    },
    description: {
      "en": "Discover the power of Automatic Number Plate Recognition (ANPR) with the Raspberry Pi NoIR Camera Module V2.1. This advanced setup leverages the camera's night vision capabilities to accurately detect and read license plates in various lighting conditions. By utilizing object detection and Optical Character Recognition (OCR), we demonstrate how to effectively capture and interpret license plates, even from challenging angles. Partner with us to enhance your surveillance systems and unlock the full potential of computer vision technology.",
      
      "no": "Oppdag kraften i automatisk nummerskiltgjenkjenning (ANPR) med Raspberry Pi NoIR-kameramodul V2.1. Denne avanserte oppsettet utnytter kameraets nattsynsegenskaper for å nøyaktig oppdage og lese nummerskilt under ulike lysforhold. Ved å bruke objektdeteksjon og optisk tegngjenkjenning (OCR) viser vi hvordan man effektivt kan fange og tolke nummerskilt, selv fra utfordrende vinkler. Samarbeid med oss for å forbedre dine overvåkingssystemer og utnytt potensialet i datavisjonsteknologi.",
      
      "mn": "Otkrijte snagu automatskog prepoznavanja registarskih tablica (ANPR) sa Raspberry Pi NoIR kamerom, modul V2.1. Ova napredna postavka koristi noćne sposobnosti kamere za precizno otkrivanje i čitanje registarskih tablica u različitim svjetlosnim uslovima. Korišćenjem detekcije objekata i optičkog prepoznavanja karaktera (OCR), pokazujemo kako efikasno uhvatiti i interpretirati registarske tablice, čak i iz izazovnih uglova. Pridružite nam se u unapređenju vaših nadzornih sistema i iskoristite puni potencijal tehnologije kompjuterskog vida."
    },
    categories: {
      "en": "Traffic",
      "no": "Trafikk",
      "mn": "Saobraćaj"
    }  
  },
  {
    img: "posture.png",
    link: "/blog-details-posture",
    date: {
      "en": "October 11, 2024",
      "no": "11. oktober 2024",
      "mn": "11. oktobar 2024."
    },
    title: {
      "en": "Enhancing Workplace Health with Computer Vision",
      "no": "Forbedring av helse på arbeidsplassen med datamaskinvisjon",
      "mn": "Unapređenje zdravlja na radnom mjestu uz kompjuterski vid"
    },
    description: {
      "en": "Explore how computer vision can help improve health while working long hours at the computer. This blog looks at four key areas: checking posture, making sure you're at a safe distance from the screen, tracking how often you blink, and monitoring how long you sit. By using simple web cameras and smart technology, we can create a healthier work environment that helps you sit up straight, take care of your eyes, and move around more. Discover how these solutions can make your work life better and support your well-being.",
      
      "no": "Utforsk hvordan datamaskinvisjon kan bidra til å forbedre helsen ved lange arbeidsøkter foran datamaskinen. Denne bloggen ser på fire viktige områder: sjekke holdning, sørge for at du er på trygg avstand fra skjermen, spore hvor ofte du blunker, og overvåke hvor lenge du sitter. Ved å bruke enkle webkameraer og smart teknologi, kan vi skape et sunnere arbeidsmiljø som hjelper deg med å sitte riktig, ta vare på øynene og bevege deg mer. Oppdag hvordan disse løsningene kan gjøre arbeidslivet bedre og støtte din velvære.",
      
      "mn": "Istražite kako kompjuterski vid može pomoći u poboljšanju zdravlja tokom dugih sati rada za računarom. Ovaj blog se fokusira na četiri ključne oblasti: provjeru držanja tijela, osiguranje sigurne udaljenosti od ekrana, praćenje učestalosti treptanja i praćenje vremena sjedjenja. Korišćenjem jednostavnih web kamera i pametne tehnologije, možemo stvoriti zdravije radno okruženje koje pomaže da sjedite pravilno, brinete o očima i više se krećete. Saznajte kako ova rješenja mogu poboljšati vaš radni život i podržati vaše blagostanje."
    },
    categories: {
      "en": "Health",
      "no": "Helse",
      "mn": "Zdravlje"
   }
  
  }
];

const BlogSidebar = () => {
  const { language, } = useContext(MainContext);

  return (
    <div className="ptf-site-wrapper animsition ptf-is--blog-sidebar">
      <Helmet>
        <title>Faintech | {translations.pageTitle[language]}</title>
      </Helmet>
      {/* End Page SEO Content */}
      <div className="ptf-site-wrapper__inner">
        <HeaderDefault />
        {/* End  HeaderHomeDefault */}

        <div className="main">
          <div className="ptf-page ptf-page--blog-sidebar">
            {/*=============================================
            Start Blog  Section 
            ============================================== */}
            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
              ></div>
              <div className="container-xxl">
                <div className="row">
                  <div className="col-xl-10">
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h1 className="large-heading">{translations.pageTitle[language]}</h1>
                    </div>
                  </div>
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer customized-slider"
                  style={{ "--ptf-xxl": "6.875rem" }}
                ></div>
              </div>
              <div className="container-xxl  customized-slider">
                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <div className="ptf-news-slider swiper-container">
                    <div className="swiper-wrapper">
                      <BlogGridSLider blogContent={blogContent}/>
                    </div>
                    {/* End swiper-wrapper */}
                  </div>
                </div>
                {/* End .ptf-animated-block */}
              </div>
              {/* End .container-xxl */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "7.5rem", "--ptf-md": "3.75rem" }}
              ></div>
            </section>
            {/* End .blog */}

            <section className="customized-slider">
              <div className="container-xxl">
                {/* <!--Divider--> */}
                <div
                  className="ptf-divider"
                  style={{
                    "--ptf-height": "1px",
                    "--ptf-color": "var(--ptf-color-14)",
                  }}
                ></div>
              </div>
            </section>

            {/*=============================================
            Start Blog With Sidebar  Section 
            ============================================== */}
            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer customized-slider"
                style={{ "--ptf-xxl": "7.5rem", "--ptf-md": "3.75rem" }}
              ></div>
              <div className="container-xxl">
                <div className="row">
                  <div className="col-xl-12">
                    {/* <!--Animated Block--> */}
                    <div className="ptf-isotope-grid">
                      <BlogFive blogContent={blogContent}/>
                    </div>
                    {/* End .ptf-isotope-grid */}
                  </div>
                </div>
              </div>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": " 5rem" }}
              ></div>
            </section>
          </div>
          {/* End .ptf-page */}
        </div>
      </div>
      {/* End .main */}

      {/* <!--Footer--> */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
    // End .ptf-is--blog-grid
  );
};

export default BlogSidebar;
