import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";
import HeaderDefault from "../../../components/header/HeaderDefault";
import NewsletterTwo from "../../../components/newsletter/NewsletterTwo";
import { MainContext } from "../../../context/MainContext";
import "./OurTeam.css";

const teamMembers = [
  {
    name: "Igor Jovančević",
    position: {
      en: "Founder",
      no: "Grunnlegger",
      mn: "Osnivač"
    },
    description: {
      en: "Igor Jovančević is a Professor at the University of Montenegro with a strong background in machine learning and computer vision. His research focuses on applying deep learning techniques to 3D point cloud analysis and industrial visual inspection, aiming to improve accuracy and efficiency in automated quality control systems. Igor is passionate about bridging the gap between cutting-edge research and practical applications, helping industries leverage AI to solve complex challenges.",
      no: "Igor Jovančević er professor ved Universitetet i Montenegro med en sterk bakgrunn i maskinlæring og datavisjon. Hans forskning fokuserer på å anvende dyp læringsteknikker på 3D punkt sky analyse og industriell visuell inspeksjon for å forbedre nøyaktighet og effektivitet i automatiserte kvalitetskontrollsystemer. Igor brenner for å bygge bro mellom banebrytende forskning og praktiske anvendelser, og hjelper industrien med å utnytte AI til å løse komplekse utfordringer.",
      mn: "Igor Jovančević je profesor na Univerzitetu Crne Gore sa bogatim iskustvom u mašinskom učenju i računarskoj viziji. Njegovo istraživanje se fokusira na primjenu tehnika dubokog učenja u analizi 3D oblaka tačaka i industrijskoj vizuelnoj inspekciji, s ciljem poboljšanja tačnosti i efikasnosti u automatizovanim sistemima kontrole kvaliteta. Igor je posvećen premošćavanju jaza između najnovijih istraživanja i praktičnih primjena, pomažući industrijama da iskoriste AI za rješavanje složenih izazova."
    },
    image: "assets/img/team/Igor.jfif"
  },
  {
    name: "Nikola Milović",
    position: {
      en: "Tech Lead",
      no: "Teknisk leder",
      mn: "Tehnički rukovodilac"
    },
    description: {
      en: "Nikola Milović is a Computer Vision Engineer and Tech Lead at FainTech, with a background in software development and a focus on artificial intelligence. He completed his Master's in Computer Science at the University of Montenegro, where he specialized in image processing and machine vision techniques. Nikola has hands-on experience in image classification, object detection, and semantic segmentation, consistently applying these skills to practical, industry-driven challenges.",
      no: "Nikola Milović er en Computer Vision-ingeniør og teknisk leder i FainTech, med en bakgrunn innen programvareutvikling og fokus på kunstig intelligens. Han fullførte sin mastergrad i informatikk ved Universitetet i Montenegro, hvor han spesialiserte seg i bildebehandling og maskinsynsteknikker. Nikola har praktisk erfaring med bildeklassifisering, objektdeteksjon og semantisk segmentering, og bruker jevnlig disse ferdighetene på bransjedrevne utfordringer.",
      mn: "Nikola Milović je inženjer računarske vizije i tehnički rukovodilac u kompaniji FainTech, s bogatim iskustvom u razvoju softvera i fokusom na vještačku inteligenciju. Magistrirao je na Prirodno-matematičkom fakultetu Univerziteta Crne Gore, na smjeru Računarske nauke, specijalizujući se za obradu slike i tehnike računarske vizije. Nikola posjeduje značajno iskustvo u klasifikaciji slika, detekciji objekata i semantičkoj segmentaciji, koje redovno primjenjuje u rješavanju izazova s kojima se suočavaju industrijski projekti."
    },
    image: "assets/img/team/Mili.jfif"
  },
  {
    name: "Nikola Pižurica",
    position: {
      en: "Machine Learning Engineer and Researcher",
      no: "Maskinlæringsingeniør og forsker",
      mn: "Inženjer i istraživač mašinskog učenja"
    },
    description: {
      en: "Nikola Pižurica is a Computer Vision Engineer at FainTech and a Teaching Assistant at the University of Montenegro. He completed his Master's degree in Computer Science, focusing on the development of regularized convolutional encoder-decoder models for image-based surface defect detection in industry. Currently, he is pursuing a Ph.D. in Computer Science, where he continues to explore advanced applications of computer vision. His work is known for striking a balance between theoretical knowledge and practical application, ensuring that cutting-edge research is effectively translated into real-world solutions.",
      no: "Nikola Pižurica er en Computer Vision-ingeniør hos FainTech og undervisningsassistent ved Universitetet i Montenegro. Han fullførte sin mastergrad i informatikk med fokus på utvikling av regulerte konvolusjonskoder-dekoder modeller for bildebasert overflatedefektdeteksjon i industrien. For tiden jobber han med doktorgradsstudier i informatikk, hvor han fortsetter å utforske avanserte bruksområder av datavisjon. Hans arbeid er kjent for å balansere mellom teoretisk kunnskap og praktisk anvendelse, noe som sikrer at banebrytende forskning effektivt oversettes til virkelige løsninger.",
      mn: "Nikola Pižurica je inženjer računarske vizije u FainTech-u i asistent na Univerzitetu Crne Gore. Magistrirao je na Prirodno-matematičkom fakultetu Univerziteta Crne Gore, na smjeru Računarske nauke, fokusirajući se na razvoj regularizovanih konvolucionih enkoder-dekoder modela za detekciju defekata na slikama. Trenutno je na doktorskim studijama iz oblasti Računarskih nauka, gdje nastavlja da istražuje napredne tehnike računarske vizije. Njegov rad je poznat po postizanju balansa između teorijskog znanja i praktične primjene, osiguravajući da se najnovija istraživanja efikasno primijene u stvarnom svijetu."
    },
    image: "assets/img/team/Dzooni.jfif"
  },
  {
    name: "Milutin Kankaraš",
    position: {
      en: "Machine Learning Engineer",
      no: "Maskinlæringsingeniør",
      mn: "Inženjer mašinskog učenja"
    },
    description: {
      en: "Milutin Kankaraš is a Computer Vision Engineer at FainTech and a Master's student at the University of Montenegro. After completing his Bachelor's degree in Computer Science, he is now diving deeper into advanced studies. Milutin is passionate about machine learning and computer vision, with a keen interest in understanding their mathematical foundations. He is also interested in exploring connections between natural sciences, mathematics, and computer science, aiming to discover new insights and practical applications that could push the boundaries of existing technology.",
      no: "Milutin Kankaraš er en Computer Vision-ingeniør hos FainTech og masterstudent ved Universitetet i Montenegro. Etter å ha fullført sin bachelorgrad i informatikk, går han nå dypere inn i avanserte studier. Milutin brenner for maskinlæring og datavisjon, med stor interesse for de matematiske grunnlagene. Han er også interessert i å utforske forbindelser mellom naturvitenskap, matematikk og informatikk, med mål om å oppdage nye innsikter og praktiske anvendelser som kan utvide grensene for eksisterende teknologi.",
      mn: "Milutin Kankaraš je inženjer računarske vizije u FainTech-u i master student na Univerzitetu Crne Gore. Nakon završenih osnovnih studija na Prirodno-matematičkom fakultetu Univerziteta Crne Gore, na smjeru Računarske nauke, upisuje master studije. Milutin je strastven u vezi sa mašinskim učenjem i računarskom vizijom, sa posebnim interesovanjem za matematiku. Takođe je zainteresovan za istraživanje povezanosti između prirodnih nauka, matematike i informatike, sa ciljem otkrivanja novih saznanja i praktičnih primjena koje mogu pomjeriti granice postojeće tehnologije."
    },
    image: "assets/img/team/Comi.jfif"
  },
  {
    name: "Ognjen Barović",
    position: {
      en: "Machine Learning Engineer",
      no: "Maskinlæringsingeniør",
      mn: "Inženjer mašinskog učenja"
    },
    description: {
      en: "Ognjen Barović is a Software and Machine Learning Engineer at FainTech, currently pursuing a Master's degree in Computing and Information Technologies at the University of Montenegro. He is passionate about exploring advanced topics in machine learning and software development. With experience as a backend Java developer and a strong track record in hackathons, Ognjen is skilled at solving complex problems through innovative and practical approaches. He is committed to continuous learning and applying his expertise to create impactful and efficient solutions.",
      no: "Ognjen Barović er en programvare- og maskinlæringsingeniør hos FainTech, og jobber for tiden med en mastergrad i informatikk og informasjonsteknologi ved Universitetet i Montenegro. Han er lidenskapelig opptatt av å utforske avanserte temaer innen maskinlæring og programvareutvikling. Med erfaring som backend Java-utvikler og en sterk merittliste fra hackathons, er Ognjen dyktig til å løse komplekse problemer gjennom innovative og praktiske tilnærminger. Han er forpliktet til kontinuerlig læring og anvendelse av sin kompetanse for å skape effektive løsninger.",
      mn: "Ognjen Barović je softverski inženjer i inženjer za mašinsko učenje u FainTech-u, trenutno na master studijama informatike i informacionih tehnologija na Univerzitetu Crne Gore. Strastveno istražuje napredne teme u mašinskom učenju i razvoju softvera. Sa iskustvom kao backend Java programer i uspjehom na takmičenjima, Ognjen uspješno rješava kompleksne probleme kroz inovativne i praktične pristupe. Posvećen je kontinuiranom učenju i primjeni svojih vještina za kreiranje efikasnih rješenja."
    },
    image: "assets/img/team/Barovic.jpg"
  },
  {
    name: "Anđela Rašković",
    position: {
      en: "Administrative Assistant",
      no: "Administrativ assistent",
      mn: "Administrativni asistent"
    },
    description: {
      en: "Anđela Rašković is responsible for managing administration and documentation, as well as providing support in the preparation and implementation of projects. Her responsibilities include assisting in the development of project concepts, organizing necessary documentation, and managing business communication with partners and clients. Additionally, she actively engages in researching marketing strategies and the visual representation of the company. Furthermore, Anđela is involved in identifying new scaling-up opportunities and exploring funding sources. She holds a Bachelor of Law degree and is currently a student in the Master's program in International Law.",
      no: "Anđela Rašković har ansvar for administrasjon og dokumentasjon, samt støtte i forberedelse og gjennomføring av prosjekter. Hennes ansvarsområder inkluderer å bistå i utviklingen av prosjektkonsepter, organisering av nødvendig dokumentasjon og forretningskommunikasjon med partnere og kunder. I tillegg er hun aktivt engasjert i forskning på markedsføringsstrategier og selskapets visuelle representasjon. Hun er også involvert i å identifisere nye vekstmuligheter og utforske finansieringskilder. Hun har en Bachelor i jus og studerer for tiden en master i internasjonal jus.",
      mn: "Anđela Rašković je odgovorna za upravljanje administracijom i dokumentacijom, kao i za podršku u pripremi i implementaciji projekata. Njene odgovornosti uključuju pomoć u razvoju projektnih koncepata, organizaciju potrebne dokumentacije i poslovnu komunikaciju s partnerima i klijentima. Pored toga, aktivno se bavi istraživanjem marketinških strategija i vizuelne reprezentacije kompanije. Takođe učestvuje u identifikovanju novih mogućnosti za proširenje i istraživanju izvora finansiranja. Diplomirala je na Pravnom fakultetu Univerziteta Crne Gore i trenutno je na master studijama iz Međunarodnog prava."
    },
    image: "assets/img/team/Andjela.jpg"
  }
];


const translations = {
  pageTitle: {
    en: "Meet Our Team",
    no: "Møt vårt team",
    mn: "Upoznajte naš tim"
  },
  pageDescription: {
    en: "We’re the people behind the innovation, always pushing the boundaries of technology and creativity...",
    no: "Vi er menneskene bak innovasjonen, og driver stadig teknologi og kreativitet fremover...",
    mn: "Mi smo ljudi iza inovacija, uvijek pomijeramo granice tehnologije i kreativnosti..."
  },
  newsletterTitle: {
    en: "Newsletter",
    no: "Nyhetsbrev",
    mn: "Bilten"
  },
  newsletterDescription: {
    en: "Stay updated with the latest innovations and insights from our talented team.",
    no: "Hold deg oppdatert med de siste innovasjonene og innsiktene fra vårt talentfulle team.",
    mn: "Budite u toku sa najnovijim inovacijama i uvidima našeg talentovanog tima."
  }
};

const OurTeam = () => {
  const { language } = useContext(MainContext);

  return (
    <div className="ptf-site-wrapper animsition ptf-is--our-team">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Faintech | {translations.pageTitle[language]}</title>
        <meta
          name="description"
          content="Get to know the talented individuals behind Faintech. Our team is dedicated to bringing you the best in AI, computer vision, and machine learning solutions."
        />
        <meta
          name="keywords"
          content="Faintech, AI team, computer vision experts, machine learning engineers, Faintech team"
        />
        <meta name="author" content="Faintech" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <div className="ptf-site-wrapper__inner">
        <HeaderDefault />
        <div className="main">
          <div className="ptf-page ptf-page--our-team">
            <section>
              <div className="ptf-spacer" style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}></div>
              <div className="container-xxl">
                <div className="row justify-content-center">
                  <div className="col-xl-8 col-lg-10">
                    <div className="ptf-animated-block" data-aos="fade" data-aos-delay="0">
                      <h1 className="large-heading fancy-heading text-center">{translations.pageTitle[language]}</h1>
                      <p className="fancy-intro text-center">
                        {translations.pageDescription[language]}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ptf-spacer" style={{ "--ptf-xxl": "4.375rem" }}></div>
              </div>
            </section>

            {/* Start Team Member Section */}
            <section className="fancy-team-section">
              <div className="ptf-spacer" style={{ "--ptf-xxl": "3.25rem", "--ptf-md": "2.125rem" }}></div>
              <div className="container-xxl">
                <div className="ptf-animated-block our-animated-block" data-aos="fade" data-aos-delay="0">
                  <div className="row g-4">
                    {teamMembers.map((member, index) => (
                      <div key={index} className="col-lg-4 col-md-6">
                        <div className="ptf-team-member fancy-team-member">
                          <div className="ptf-team-member__image-wrapper">
                            <img src={member.image} alt={member.name} className="ptf-team-member__image" />
                          </div>
                          <div className="ptf-team-member__content">
                            <h3 className="ptf-team-member__name fancy-text">{member.name}</h3>
                            <p className="ptf-team-member__position fancy-position">{member.position[language]}</p>
                            <p className="ptf-team-member__description fancy-description">{member.description[language]}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="ptf-spacer" style={{ "--ptf-xxl": "8.75rem", "--ptf-md": "4.375rem" }}></div>
            </section>

            {/* Start Newsletter Section */}
            <section>
              <div className="ptf-spacer" style={{ "--ptf-xxl": "9.375rem", "--ptf-md": "4.6875rem" }}></div>
              <div className="container-xxl">
                <div className="row align-items-center">
                  <div className="col-lg-4">
                    <div className="ptf-animated-block" data-aos="fade" data-aos-delay="0">
                      <h1 className="large-heading fancy-heading text-center">{translations.newsletterTitle[language]}</h1>
                      <div className="ptf-spacer" style={{ "--ptf-xxl": "1.25rem" }}></div>
                      <p className="fancy-intro text-center">
                        {translations.newsletterDescription[language]}
                      </p>
                    </div>
                    <div className="ptf-spacer" style={{ "--ptf-lg": "3.75rem", "--ptf-md": "1.875rem" }}></div>
                  </div>
                  <div className="col-lg-6 offset-lg-2">
                    <div className="ptf-animated-block" data-aos="fade" data-aos-delay="100">
                      <NewsletterTwo />
                    </div>
                  </div>
                </div>
              </div>
              <div className="ptf-spacer" style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}></div>
            </section>
          </div>
        </div>
      </div>

      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default OurTeam;
