import React, {useContext} from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import {MainContext} from "../../context/MainContext";

const FaqContent = [
  {
    title: {
      "en": "How experienced are your computer vision consultants?",
      "no": "Hvor erfarne er computer vision-konsulentene dine?",
      "mn": "Koliko su iskusni vaši konsultanti za računarsku viziju?"
    },
    desc: {
      "en": "Our computer vision team includes specialists with diverse experience levels, from engineers with 1 year of hands-on experience to researchers with over 10 years in advanced CV research. We bring both fresh perspectives and deep expertise, allowing us to create practical computer vision solutions that fit your needs.",
      "no": "Vårt computer vision-team består av spesialister med ulik erfaringsnivå, fra ingeniører med 1 års praktisk erfaring til forskere med over 10 års erfaring innen avansert CV-forskning. Vi kombinerer nye perspektiver med dyp ekspertise, noe som gjør oss i stand til å skape praktiske computer vision-løsninger som passer til dine behov.",
      "mn": "Naš tim za računarsku viziju uključuje stručnjake sa različitim nivoima iskustva, od inženjera sa jednom godinom praktičnog iskustva do istraživača sa preko 10 godina iskustva u naprednom istraživanju CV-a. Donosimo svježe perspektive i duboko znanje, omogućavajući nam da kreiramo praktična rješenja za računarsku viziju koja odgovaraju vašim potrebama."
    },
    expand: "a"
  },
  {
    title: {
      "en": "Do I need to have existing computer vision expertise to work with your consultancy firm?",
      "no": "Trenger jeg å ha eksisterende computer vision-ekspertise for å jobbe med konsulentfirmaet ditt?",
      "mn": "Da li moram imati postojeće znanje iz oblasti računarske vizije da bih radio sa vašom konsultantskom firmom?"
    },
    desc: {
      "en": "No. You focus on building your product, and we handle the computer vision modules. We work with organizations at all stages of their computer vision journey, providing the support and insights needed to reach your goals.",
      "no": "Nei. Du bygger produktet ditt, vi tar oss av computer vision-modulene. Vi samarbeider med organisasjoner på alle stadier av deres computer vision-reise, og gir støtte og innsikt for å hjelpe deg å nå målene dine.",
      "mn": "Ne. Vi se fokusirate na izgradnju vašeg proizvoda, a mi se bavimo modulima za računarsku viziju. Radimo sa organizacijama u svim fazama njihovog razvoja u oblasti računarske vizije, pružajući podršku i uvide potrebne za ostvarenje vaših ciljeva."
    },
    expand: "b"
  },
  {
    title: {
      "en": "How long does a computer vision engagement with your consultancy firm typically last?",
      "no": "Hvor lenge varer vanligvis et computer vision-engasjement med konsulentfirmaet ditt?",
      "mn": "Koliko obično traje angažman za računarsku viziju sa vašom konsultantskom firmom?"
    },
    desc: {
      "en": "It really depends on your needs. We’ve worked on projects that lasted just a few months, and we also have partners we've been supporting for several years. Whether you need a quick solution or long-term collaboration, we're here for you.",
      "no": "Det kommer helt an på hva du trenger. Vi har jobbet med prosjekter som har vart bare noen måneder, og vi har også partnere som vi har støttet i flere år. Enten du trenger en rask løsning eller et langsiktig samarbeid, er vi her for deg.",
      "mn": "To zaista zavisi od vaših potreba. Radili smo na projektima koji su trajali samo nekoliko mjeseci, a imamo i partnere koje podržavamo već nekoliko godina. Bez obzira na to da li su vam potrebna brza rješenja ili dugoročna saradnja, tu smo za vas."
    },
    expand: "c"
  }
];



const Faq = () => {
  const {language,} = useContext(MainContext);
  return (
    <>
      <dl className="ptf-accordion">
        <Accordion preExpanded={["a"]} allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem key={i} uuid={item.expand}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <dt>{item.title[language]}</dt>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel>
                <dd>{item.desc[language]}</dd>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </dl>
    </>
  );
};

export default Faq;
