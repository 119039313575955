import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { MainContext } from "../../context/MainContext";


const BlogFive = ({blogContent}) => {
  const { language, } = useContext(MainContext);

  return (
    <>
      {blogContent.map((val, i) => (
        <div className="grid-item" key={i}>
          {/* <!--Blog Post--> */}
          <article className="ptf-post ptf-post--style-3">
            <div className="ptf-post__media">
              <Link className="ptf-work__link" to={val.link}></Link>
              <img
                src={`assets/img/blog/slider/${val.img}`}
                alt={val.categories[language]}
                loading="lazy"
              />
            </div>
            <div className="ptf-post__content">
              <header className="ptf-post__header">
                <h3 className="ptf-post__title">
                  <Link to={val.link}>{val.title[language]}</Link>
                </h3>
                <div className="ptf-post__meta">
                  <span className="cat">{val.categories[language]}</span>
                  <span className="date">{val.date[language]}</span>
                </div>
                <div className="ptf-post__excerpt">
                  <p>{val.description[language]}</p>
                </div>
              </header>
            </div>
          </article>
        </div>
      ))}
    </>
  );
};

export default BlogFive;
