import React from "react";

const dropDownItems = [
  { name: "Home", path: "/" },
  { name: "Services", path: "/services" },
  { name: "Blog", path: "/blog" },
  { name: "Our team", path: "/our-team" },
  { name: "Contact us", path: "/contact" },
];

const MobileMenu = ({ isOpen, onClose, renderLink }) => {
  const defaultRenderLink = (item, className) => (
    <a 
      href={item.path}
      className={className}
      onClick={(e) => {
        onClose();
      }}
    >
      {item.name}
    </a>
  );

  const linkRenderer = renderLink || defaultRenderLink;

  return (
    <div className="tailwind-wrapper">
      {/* Overlay */}
      <div 
        className={`fixed inset-0 bg-black/50 transition-opacity duration-300 ease-in-out z-[9998] ${
          isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'
        }`}
        onClick={onClose}
      />
      
      {/* Menu */}
      <div 
        className={`custom-mobile-menu fixed top-0 right-0 w-full max-w-lg h-screen bg-white shadow-lg z-[9999] overflow-y-auto transform transition-transform duration-1000 ease-in-out ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="p-8 h-full flex flex-col">
          <div className="flex justify-end mb-8">
            <button 
              className="text-4xl text-black hover:text-gray-600 transition-colors duration-300 p-2 cursor-pointer"
              onClick={onClose}
              aria-label="Close menu"
            >
              ×
            </button>
          </div>
          
          <nav className="flex-grow">
            <ul className="list-none p-0 m-0">
              {dropDownItems.map((item, i) => (
                <li key={i} className="mb-4">
                  {linkRenderer(
                    item,
                    "block py-2 text-xl text-black no-underline transition-all duration-300 hover:text-gray-600 hover:pl-2"
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
