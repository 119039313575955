import React from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../components/header/HeaderDefault";
import CopyRight from "../../components/footer/copyright/CopyRight";
import Footer from "../../components/footer/Footer";

const FaqFaintech = () => {
  return (
    <div>
      <Helmet>
        <title>Faintech</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--faq">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-10">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading">Terms</h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="fz-18">
                      <div class="section">
                        <h1>Terms and Conditions</h1>
                        <p>Last Updated: 17.09.2024.</p>
                        <p>
                          These Terms and Conditions ("Terms") are a legal
                          agreement between you ("User") and FainTech LLC,
                          located at 8 Serdara Jola Piletića, Podgorica,
                          Montenegro. By accessing or using our website at{" "}
                          <a href="http://faintech.me">faintech.me</a>, you
                          agree to be bound by these Terms. If you do not agree
                          with these Terms, you must discontinue your use of the
                          website.
                        </p>

                        <h2>1. Description of Services</h2>
                        <p>
                          FainTech is a consulting firm focused on computer
                          vision technologies. We offer paid services that allow
                          businesses to leverage visual data, such as images, 3D
                          point clouds, and video content, to enhance their
                          operations. While the website is publicly accessible,
                          it is strictly informational. Unauthorized copying,
                          use, or distribution of our content is prohibited.
                        </p>

                        <h2>2. Acceptance of Terms</h2>
                        <p>
                          By accessing our website, you agree to comply with
                          these Terms. Your continued use of the website implies
                          acceptance of the current Terms. If you do not agree
                          with these Terms, please refrain from using the
                          website.
                        </p>

                        <h2>3. User Conduct</h2>
                        <p>
                          You are allowed to browse the FainTech website, but
                          you are prohibited from:
                        </p>
                        <ul>
                          <li>
                            Copying, distributing, or modifying any content
                            without explicit permission.
                          </li>
                          <li>
                            Using any automated tools or systems to extract data
                            from our website.
                          </li>
                          <li>
                            Attempting to interfere with the website's
                            functionality.
                          </li>
                        </ul>

                        <h2>4. Intellectual Property</h2>
                        <p>
                          All content on the website, including text, graphics,
                          logos, and software, is owned by FainTech LLC or
                          licensed to us. You may not use any part of our
                          intellectual property without prior written consent.
                        </p>

                        <h2>5. Limitations of Liability</h2>
                        <p>
                          FainTech is not liable for any direct, indirect,
                          incidental, or consequential damages arising from your
                          use of, or inability to use, our website or services.
                          We do not guarantee that the website will be free of
                          errors or interruptions.
                        </p>

                        <h2>6. Modification of Terms</h2>
                        <p>
                          FainTech reserves the right to modify these Terms at
                          any time. Any changes will be posted on the website,
                          and it is your responsibility to review them
                          periodically. Your continued use of the website
                          signifies your acceptance of any revised Terms.
                        </p>

                        <h2>7. Newsletter Subscription</h2>
                        <p>
                          Users can subscribe to receive newsletters via email.
                          Subscriptions are voluntary and free of charge. You
                          can unsubscribe at any time by following the link in
                          the email.
                        </p>

                        <h2>8. Governing Law</h2>
                        <p>
                          These Terms shall be governed by the laws of
                          Montenegro and, where applicable, by EU regulations.
                          By using our website, you agree to submit to the
                          jurisdiction of the Montenegrin courts for any
                          disputes arising from these Terms.
                        </p>
                      </div>

                      <div class="section">
                        <h1>Privacy Policy</h1>
                        <p>Effective Date:</p>
                        <p>
                          This Privacy Policy explains how FainTech LLC ("we" or
                          "us") collects, uses, and safeguards the personal data
                          of visitors to our website at{" "}
                          <a href="http://faintech.me">faintech.me</a>. By
                          accessing our website, you agree to the terms of this
                          Privacy Policy.
                        </p>

                        <h2>1. Information We Collect</h2>
                        <p>We collect the following personal data:</p>
                        <ul>
                          <li>
                            Contact Data: When users submit inquiries through
                            our contact form, we collect name, email address,
                            and any other information provided.
                          </li>
                          <li>
                            Usage Data: We gather analytics about how visitors
                            use our website (e.g., IP addresses, browser type,
                            access times) to improve user experience.
                          </li>
                        </ul>

                        <h2>2. Purpose of Data Collection</h2>
                        <p>We collect personal information to:</p>
                        <ul>
                          <li>
                            Respond to inquiries submitted through our contact
                            form.
                          </li>
                          <li>
                            Analyze traffic and improve the website’s
                            functionality and content.
                          </li>
                          <li>
                            Send newsletters or updates to subscribers, if they
                            have opted in.
                          </li>
                        </ul>

                        <h2>3. Cookies and Tracking</h2>
                        <p>
                          Our website uses cookies to collect analytical data
                          that helps us understand how visitors use our site.
                          Cookies do not store personal information unless
                          provided directly by the user. You can adjust your
                          browser settings to refuse cookies, but this may limit
                          your ability to use certain features of the website.
                        </p>

                        <h2>4. How We Use Your Data</h2>
                        <p>
                          FainTech uses your data for the following purposes:
                        </p>
                        <ul>
                          <li>
                            Communication: To respond to your inquiries or
                            feedback.
                          </li>
                          <li>
                            Analytics: To monitor website traffic and usage
                            patterns.
                          </li>
                          <li>
                            Marketing: If you opt into our newsletter, we will
                            use your contact details to send you updates about
                            our services.
                          </li>
                        </ul>

                        <h2>5. Data Security</h2>
                        <p>
                          We use appropriate technical and organizational
                          measures to protect your personal data from
                          unauthorized access, alteration, or destruction.
                          However, we cannot guarantee the security of data
                          transmitted over the internet.
                        </p>

                        <h2>6. Data Retention</h2>
                        <p>
                          We retain personal data for as long as it is necessary
                          to fulfill the purposes for which it was collected or
                          as required by law. Contact information submitted via
                          inquiries is retained until the query is resolved or
                          until the user requests deletion.
                        </p>

                        <h2>7. Your Rights</h2>
                        <p>You have the right to:</p>
                        <ul>
                          <li>Access the personal data we hold about you.</li>
                          <li>
                            Request correction of inaccurate or incomplete data.
                          </li>
                          <li>Request deletion of your personal data.</li>
                          <li>
                            Withdraw consent to the processing of your data for
                            marketing purposes by unsubscribing from our
                            newsletter.
                          </li>
                        </ul>
                        <p>
                          To exercise these rights, please contact us at{" "}
                          <a href="mailto:office@faintech.me">
                            office@faintech.me
                          </a>
                          .
                        </p>

                        <h2>8. Third-Party Services</h2>
                        <p>
                          We do not share your personal data with third parties,
                          except for analytics services that help us monitor
                          traffic. Any third-party providers are bound by
                          confidentiality agreements and must adhere to GDPR
                          regulations.
                        </p>

                        <h2>9. International Data Transfers</h2>
                        <p>
                          We do not transfer your personal data outside the
                          European Economic Area (EEA). If international
                          transfers are required, we will ensure that adequate
                          safeguards are in place to protect your data.
                        </p>

                        <h2>10. Changes to this Privacy Policy</h2>
                        <p>
                          We may update this Privacy Policy from time to time.
                          Any changes will be posted on our website, and where
                          appropriate, we will notify you via email. Your
                          continued use of the website following any updates
                          signifies your acceptance of the changes.
                        </p>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
        </div>
      </div>

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default FaqFaintech;
